import './index.scss'
import LoginBlock from './components/login-block';

const Login = () => {
  return (
    <div className='login'>
      <div className='login-pannel'>
        <LoginBlock />
        <div className='login-pannel-right'></div>
      </div>
    </div>
  );
};

export default Login;