import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Input, Form, Icon, Message } from '@alifd/next'
import { getQueryValue } from '@/utils/getQuery'
import './index.scss'
import API from '@/network'
import logo from '@/assets/images/hho-logo.png'

const { Item } = Form
const DEFAULT_DATA = {
  username: '',
  password: '',
  captcha: '',
  captchaId: '',
}

const LoginBlock = (
  props = {
    dataSource: DEFAULT_DATA,
  }
) => {
  const history = useHistory()
  const { dataSource = DEFAULT_DATA } = props
  const [postData, setValue] = useState(dataSource)
  const [picPath, setPicPath] = useState('')

  const formChange = (values) => {
    setValue(values)
  }

  // 验证码
  const refreshCaptcha = () => {
    API.base.captcha().then((res) => {
      setPicPath(res.data.picPath)
      setValue({ ...postData, captchaId: res.data.captchaId, captcha: '' })
      window.portalsend({ event: 'refresh-captcha' })
    })
  }

  // 钉钉登录
  const ddLogin = async () => {
    const auth_code = getQueryValue('authCode')
    const state = getQueryValue('state')

    if (!auth_code || !state) return

    try {
      const res = await API.base.ddLogin({ auth_code, state })
      localStorage.setItem('__hho_token__', res.data.token)
      localStorage.setItem('__hho_user_name__', res.data.user.nickName)
      localStorage.setItem('__hho_user_id__', res.data.user.ID)
      localStorage.setItem('vuex', JSON.stringify({ user: res.data }))

      let search = window.location.search?.replace(`authCode=${auth_code}`, '')
      Message.success('登录成功，正在跳转...')
      const __hho_redirect_url__ = localStorage.getItem('__hho_redirect_url__')
      if (__hho_redirect_url__) {
        localStorage.removeItem('__hho_redirect_url__')
        window.location.href = __hho_redirect_url__
        return
      }
      if (getQueryValue('getToken')) {
        search = search?.replace('getToken=true', '')
        history.push('/portal/home' + search)
        window.location.reload()
      } else {
        history.push('/portal/home' + search)
      }
    } catch (err) {
      refreshCaptcha()
      Message.error(err)
    }
  }

  // 账号密码登录
  const handleSubmit = useCallback(
    async (values, errors) => {
      if (errors) {
        refreshCaptcha()
        Message.error(errors)
        return
      }

      try {
        await API.base.login(postData)
        window.portalsend({
          event: 'portal-login',
          username: postData.username,
        })
        Message.loading({
          title: '登录成功，正在跳转...',
          duration: 1000,
          afterClose: () => {
            history.push('/portal/home' + window.location.search)
          },
        })
      } catch (err) {
        refreshCaptcha()
        Message.error(err)
      }
    },
    [postData]
  )

  // 打开钉钉授权登录页面
  const openDDAuth = () => {
    const appId = 'dingvd9xpacx9pfxbn0l'
    const redirectUrl = encodeURIComponent(
      window.location.origin + '/login' + window.location.search
    )
    const gotourl =
      'https://login.dingtalk.com/oauth2/challenge.htm?client_id=' +
      appId +
      '&response_type=code&scope=openid%20corpid&nonce=nonce&state=state&prompt=consent&redirect_uri=' +
      redirectUrl
    window.portalsend({ event: 'open-dingding-auth' })
    window.location.href = gotourl
  }

  useEffect(() => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const redirectUrl = url.searchParams.get('_redirectUrl')
    if (redirectUrl) localStorage.setItem('__hho_redirect_url__', redirectUrl)
    refreshCaptcha()
    ddLogin()
  }, [])

  return (
    <div className="login-block">
      <div className="logo-title">
        <img src={logo} alt="HHO" />
        <p>HHO-PORTAL</p>
      </div>

      <Form value={postData} onChange={formChange} size="large">
        <Item required requiredMessage="请输入用户名">
          <Input
            name="username"
            placeholder="请输入用户名"
            innerAfter={<Icon type="account" size="xs" style={{ margin: 8 }} />}
          />
        </Item>
        <Item required requiredMessage="请输入密码">
          <Input.Password name="password" placeholder="请输入密码" />
        </Item>
        <Item
          required
          requiredMessage="请输入验证码"
          style={{ position: 'relative' }}
        >
          <Input
            name="captcha"
            placeholder="请输入验证码"
            style={{ width: '60%' }}
          />
          <div className="captcha">
            <img
              src={picPath}
              width="100%"
              height="100%"
              alt="请输入验证码"
              onClick={refreshCaptcha}
            />
          </div>
        </Item>
        <Item>
          <Form.Submit
            type="primary"
            onClick={openDDAuth}
            style={{ width: '49%', marginRight: '2%' }}
          >
            钉钉免登
          </Form.Submit>
          <Form.Submit
            type="normal"
            onClick={handleSubmit}
            validate
            style={{ width: '49%', marginLeft: '0' }}
          >
            登录
          </Form.Submit>
        </Item>
      </Form>
    </div>
  )
}

export default LoginBlock
