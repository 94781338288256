import { registerMicroApps, start } from 'qiankun'
import React, { useEffect, useState } from 'react'
import { Shell, ConfigProvider, Message } from '@alifd/next'

import API from '@/network'
import Menu from './components/menu'
import Footer from './components/footer'
import HeaderAvatar from './components/header-avatar'

import './index.scss'

const AppName = 'PORTAL'

export default function BasicLayout({ children }) {
  const [userInfo, setUserInfo] = useState({})
  const [asyncMenu, setAsyncMenu] = useState([])
  const [isIframe, setIsIframe] = useState(false)
  const [microAppLayouts, setMicroAppLayouts] = useState({})
  const [appName, setAppName] = useState('')

  const microAppLayout = microAppLayouts[appName] || {}

  const {
    hideHeader = false,
    hideFooter = false,
    hideSideMenu = false,
  } = microAppLayout

  const getUserInfo = () => {
    try {
      API.user
        .getUserInfo()
        .then((res) => {
          setUserInfo(res.data.userInfo)
          localStorage.setItem('__hho_user_name__', res.data.userInfo.nickName)
          localStorage.setItem('__hho_user_id__', res.data.userInfo.ID)
          localStorage.setItem('__hho_user_uuid__', res.data.userInfo.uuid)
          localStorage.setItem('vuex', JSON.stringify({ user: res.data }))
        })
        .catch((err) => {
          Message.notice('未登录')
          window.location.href = '/login' + window.location.search
        })
    } catch (err) {
      Message.error(err)
    }
  }

  const getAsyncMenu = () => {
    try {
      API.menu.getAsyncMenu().then((res) => {
        setAsyncMenu(res.data.menus)
      })
    } catch (err) {
      Message.error(err)
    }
  }

  const register = (apps) => {
    registerMicroApps(apps, {
      beforeLoad: (app) => {
        // console.log('before load', app.name)
      },
      afterMount: (app) => {
        setAppName(app.name)
        console.log(`${app.name} mounted`)
      },
      beforeUnmount: (app) => {
        // window.history.replaceState(null, null, window.location.href)
        // console.log('beforeUnmount', app.name)
      },
    })
  }

  useEffect(() => {
    getUserInfo()
    getAsyncMenu()
    setIsIframe(window.location.pathname === '/iframe')

    API.menu.getMicroApps().then((res) => {
      if (res.code !== 0) return
      const microAppLayouts = {}
      const apps = res.data.resysDictionary.sysDictionaryDetails.map((d) => {
        const [label, value, version, hideSideMenu, hideHeader, hideFooter] =
          d.label.split('$')

        microAppLayouts[value] = {
          hideSideMenu: hideSideMenu === 'true',
          hideHeader: hideHeader === 'true',
          hideFooter: hideFooter === 'true',
        }

        return {
          name: value,
          entry:
            process.env.REACT_APP_OSS_BASE_URL +
            '/' +
            value +
            '/' +
            version +
            '/',
          container: '#microWebContainer',
          activeRule: '/' + value,
          props: {
            token: localStorage.getItem('__hho_token__'),
          },
        }
      })
      console.log('micro apps: ', apps)
      setMicroAppLayouts(microAppLayouts)
      register(apps)
      start({
        prefetch: (apps) => {
          return { criticalAppNames: [], minorAppsName: [] }
        },
      })
    })
  }, [window.location.pathname])

  return (
    <ConfigProvider device="desktop">
      <Shell
        style={{
          minHeight: '100vh',
          height: 'calc(100vh - 56px)',
          overflow: 'auto',
        }}
        type="brand"
        fixedHeader={false}
      >
        {hideHeader && (
          <Shell.Branding>
            <div className="logo">
              <img
                image="http://img.alicdn.com/tfs/TB1.ZBecq67gK0jSZFHXXa9jVXa-904-826.png"
                text="Logo"
              />
              <span>{AppName}</span>
            </div>
          </Shell.Branding>
        )}
        {hideHeader !== true && (
          <Shell.Navigation
            direction="hoz"
            style={{
              marginRight: 10,
            }}
          ></Shell.Navigation>
        )}
        {hideHeader !== true && (
          <Shell.Action>
            <HeaderAvatar {...userInfo} />
          </Shell.Action>
        )}
        {hideSideMenu !== true && (
          <Shell.Navigation
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <Menu menus={asyncMenu} />
          </Shell.Navigation>
        )}

        <Shell.Content
          className={hideSideMenu ? 'shell-content-hide-menu' : ''}
        >
          {children}
          <div
            id="microWebContainer"
            data-frame={isIframe}
            style={{
              minHeight: isIframe ? '0px' : 'calc(100vh - 148px)',
            }}
          ></div>
        </Shell.Content>
        {hideFooter !== true && (
          <Shell.Footer>
            <Footer />
          </Shell.Footer>
        )}
      </Shell>
    </ConfigProvider>
  )
}
