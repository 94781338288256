import { useEffect, useState } from 'react';
import { getQueryValue, queryParams } from '@/utils/getQuery';

const IframeLayout = () => {
  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {
    const url = getQueryValue('url');
    const newUrl = decodeURIComponent(url)
    setIframeUrl(newUrl)
    const xToken = queryParams('x-token', newUrl)
    const xUserId = queryParams('x-user-id', newUrl)
    const itemId = queryParams('itemId', newUrl)

    const fn = () => {
      window.postMessage({
        event: 'portal-ads-token-response',
        data: {
          'x-token': xToken,
          'x-user-id': xUserId,
          'itemId': itemId,
        }
      })
    }
    const handler =  ev => {
      if (ev?.data?.event === 'portal-ads-token') {
        fn()
      }
    }

    window.addEventListener('message', handler)
    return () => {
      window.removeEventListener('messsage', handler)
    }
  }, [window.location.search])

  return (
    <iframe src={iframeUrl} title="portal" frameBorder="0" width="100%" style={{ minHeight: 'calc(100vh - 120px)'}} />
  );
};

export default IframeLayout;