/* eslint-disable no-undef */
import ASTLogger, { ASTLoggerEvent } from '@hho-middle/athena-sls-trace'
import { ASTLoggerEnv } from '@hho-middle/athena-sls-trace'

const portalLogger = new ASTLogger({
  env: ASTLoggerEnv[process.env.REACT_APP_TRACK_STORE_ENV],
  project: 'athena-service',
})

window.portalsend = function (obj) {
  const event = obj?.event
  obj?.event && delete obj.event
  portalLogger.log(
    new ASTLoggerEvent({
      event,
      data: { ...obj },
    })
  )
}
