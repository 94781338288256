/* eslint-disable no-extend-native */
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Layout from './layout'
import Login from './pages/login'

Array.prototype.subarray = function(start, end) {
    if (!end) end = this.length
    if (end < 0) end = this.length + end + 1
    return [...this].slice(start, end)
};

const whiteList = ['login']
const modulesFiles = require.context('./pages/', true, /\.jsx$/)
const childRoutes = []
const root = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    childRoutes: []
  },
  {
    path: '/',
    component: Layout,
    childRoutes: [],
  }
]
modulesFiles.keys().forEach(filename => {
  const routerPath = filename.split('/').subarray(1, -1).reverse().subarray(1, -1).reverse().join('/')
  if (whiteList.indexOf(routerPath) < 0) {
    childRoutes.push({
      path: '/' + routerPath,
      component: lazy(() => new Promise((resolve) => {
        resolve(modulesFiles(filename))
      }))
    })
  }
});

root[1].childRoutes = childRoutes
console.log(root)

const RouteConfig = (props) => {
    return (
        <Switch>
        {props.routes.map((route, index) => {
            if (route.redirect) return <Redirect key={route.path || index} exact={route.exact} strict={route.strict} from={route.path} to={route.redirect}/>
    
            return <Route key={route.path || index} path={route.path} exact={route.exact} strict={route.strict}
                render={() => {
                    if (!route.component) return <RouteConfig routes={route.childRoutes}/> 
                    return <Suspense fallback={<div>ggg</div>}>
                        <route.component route={route}>
                            <RouteConfig routes={route.childRoutes}/> 
                        </route.component>
                    </Suspense>
                }}
            />
        })}
        </Switch>
    )
}

export default () => {
    return <Router><RouteConfig routes={root}/></Router>
}
