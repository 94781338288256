import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Nav, Icon } from '@alifd/next';

const CustomIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2949274_h4pas1eoov7.js',
});

const AUTH_CONFIG = {
  admin: true,
  guest: false,
};


const { SubNav } = Nav;
const NavItem = Nav.Item;
const routeMap = {}
// const menuKeys = {}

function getNavMenuItems (menusData, initIndex, auth, parentPath = '') {
  if (!menusData) {
    return [];
  }

  return menusData
    .filter((item) => (item.name !== '404' && !item.hidden && (!item.children || (item.children && item.children.some(ch => !ch.hidden)))))
    .map((item, index) => getSubMenuOrItem(item, `${initIndex}-${index}`, auth, parentPath));
}


function getIframe (item) {
  let url = item.parameters.filter(d => d.key === 'iframe')[0]?.value || ''
  const params = []
  item.parameters.length > 0 && item.parameters.some(d => d.key === 'x-token') && params.push(`x-token=${localStorage.getItem('__hho_token__')}`)
  item.parameters.length > 0 && item.parameters.some(d => d.key === 'x-user-id') && params.push(`x-user-id=${localStorage.getItem('__hho_user_id__')}`)
  const paramsStr = params.join('&')
  return {
    isIframe: item.parameters.length > 0 && item.parameters.some(d => d.key === 'iframe'),
    url: `${url}${url?.indexOf('?') !== -1 ? '&' : '?'}${paramsStr}`

  }
}

function getSubMenuOrItem (item, index, auth, parentPath) {
  const { isIframe, url } = getIframe(item)
  if (isIframe) {
    const newurl = `/iframe?url=${encodeURIComponent(url)}`;
    routeMap[newurl] = item.meta.title
    const navItem = (
      <NavItem key={item.path} icon={<CustomIcon type={'icon-' + item.meta.icon} />}>
        <Link to={'/iframe?url=' + encodeURIComponent(url)} >{item.meta.title}</Link>
      </NavItem>
    );
    return navItem;
  }
  item.micro = item.parameters.filter(d => d.key === 'micro')[0]?.value || 'portal'
  item.microPath = item.parameters.filter(d => d.key === 'microPath')[0]?.value || ''

  if (item.children && item.children.some((child) => child.ID)) {
    const childrenItems = getNavMenuItems(item.children, index, auth, item.path);
    if (childrenItems && childrenItems.length > 0) {
      const subNav = (
        <SubNav key={item.path} icon={<CustomIcon type={'icon-' + item.meta.icon} />} label={item.meta.title}>
          {childrenItems}
        </SubNav>
      );
      return subNav;
    }

    return null;
  }

  parentPath = (parentPath && parentPath !== '/' && parentPath !== 'inventoryManagement1') ? (parentPath + '/') : ''
 
  const newurl = `/${item.micro?.trim()}/${item.microPath?.trim()}`;
  routeMap[newurl] = item.meta.title
  // menuKeys[newurl] = {
  //   key: item.path,
  //   openKey: item.micro?.replaceAll('-', '')
  // }

  const navItem = (
    <NavItem key={item.path} icon={<CustomIcon type={'icon-' + item.meta.icon} />}>
      <Link to={`/${item.micro}/${item.microPath}`}>{item.meta.title}</Link>
    </NavItem>
  );
  
  return navItem;
}

const Navigation = (props, context) => {
  const [openKeys, setOpenKeys] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);
  const { location, menus } = props;
  const { pathname } = location;

  const { isCollapse } = context;

  useEffect(() => {
    const curSubNav = menus.find((menuConfig) => {
      return menuConfig.children && checkChildPathExists(menuConfig);
    });
    

    function checkChildPathExists (menuConfig) {
      let parentPath = menuConfig.path ? menuConfig.path + '/' : ''
      return menuConfig.children.some((child) => {
        const childPath = '/' + child.micro + '/' + parentPath + child.path
        
        if (childPath === pathname) {
          setSelectKeys([child.path]);
        }
        return child.children ? checkChildPathExists(child) : childPath === pathname;
      });
    }
    if (curSubNav) {
      setOpenKeys([curSubNav.path]);
    }
    let path = window.location.pathname;
    if (path.indexOf('/iframe') !== -1) { 
      path += window.location.search
    }
    window.portalsend({
      url: path, title: routeMap[path] || 'portal',
      event: 'container-pathname-change'
    })
    document.title = routeMap[path] || 'portal'

    
    // if (selectKeys?.[0] || !menuKeys[path] || !menuKeys[path]?.key) {
    //   setOpenKeys(location.pathname.split('/')[1].replaceAll('-', ''))
    //   return
    // }
    
    // setSelectKeys([menuKeys[path].key])
    // setOpenKeys([menuKeys[path]?.openKey])
  }, [pathname, menus]);

  const handleSelect = (selectedKeys) => {
    setSelectKeys(selectedKeys);
    let path = window.location.pathname;
    if (path.indexOf('/iframe') !== -1) { 
      path += window.location.search
    }
    
    window.portalsend({
      url: path, title: routeMap[path] || 'portal',
      event: 'container-menu-click'
    })
  }

  return (
    <Nav
      type="normal"
      openKeys={openKeys}
      selectedKeys={selectKeys}
      defaultSelectedKeys={selectKeys}
      embeddable
      activeDirection="right"
      iconOnly={isCollapse}
      openMode="single"
      hasArrow={false}
      onSelect={handleSelect}
      mode={isCollapse ? 'popup' : 'inline'}
      onOpen={(keys) => {
        // @ts-ignore
        setOpenKeys(keys);
      }}
    >
      {getNavMenuItems(menus, 0, AUTH_CONFIG['admin'])}
    </Nav>
  );
};

Navigation.contextTypes = {
  isCollapse: PropTypes.bool
};
const PageNav = withRouter(Navigation);
export default PageNav;