import * as React from 'react';
import API from '@/network'
import { Avatar, Overlay, Menu, Icon, Message } from '@alifd/next';
import './index.scss';

const { Item } = Menu;
const { Popup } = Overlay;

const logout = async () => {
  await API.user.logout()
  window.portalsend({
    event: 'logout'
  })
  localStorage.clear()
  Message.loading({
    title: '正在退出登录...',
    duration: 1000,
    afterClose: () => {
      window.location.href = '/login' + window.location.search
    }
  })
}

const changeAuthority = async (id, name) => {
  try {
    await API.user.setUserAuthority({ authorityId: id })
    window.portalsend({
      event: 'change-authority',
      authorityId: id,
      authorityName: name
    })
    setTimeout(() => {
      window.location.href = '/portal/home' + window.location.search
      // localStorage.clear()
      // document.cookie = ''
    }, 1)
  } catch (err) {
    Message.error(err)
  }
}

const UserProfile = ({ nickName, authority, headerImg }) => {
  return (
    <div className='profile'>
      <div className={headerImg}>
        <Avatar src={headerImg} alt={nickName} />
      </div>
      <div className='container-content'>
        <h4>{nickName}</h4>
        <span>当前角色：{authority.authorityName}</span>
      </div>
    </div>
  );
};

const HeaderAvatar = (props) => {
  const { nickName, headerImg, authorityId, authorities } = props;
  const otherAuthorities = authorities.filter(d => d.authorityId !== authorityId)

  return (
    <Popup
      trigger={
        <div className='headerAvatar'>
          <Avatar size="small" src={headerImg} alt={nickName} />
          <span
            style={{
              marginLeft: 10,
              lineHeight: '32px'
            }}
          >
            {nickName}
          </span>
        </div>
      }
      triggerType="hover"
    >
      <div className='avatarPopup'>
        <UserProfile {...props} />
        <Menu className='menu'>
          {otherAuthorities.map((item, idx) => (
            <Item key={item.authorityId} onClick={() => changeAuthority(item.authorityId, item.authorityName)}>
              切换为：{item.authorityName}
            </Item>
          ))
          }
          {/* <Item>
            <Icon size="small" type="account" style={{ marginRight: '5px'}}/>
            个人信息
          </Item> */}
          {/* <Item>
            <Icon size="small" type="set" style={{ marginRight: '5px'}} />
            系统设置
          </Item> */}
          <Item onClick={logout}>
            <Icon size="small" type="exit" style={{ marginRight: '5px'}} />
            退出
          </Item>
        </Menu>
      </div>
    </Popup>
  );
};

HeaderAvatar.defaultProps = {
  nickName: 'hho',
  userName: 'hho-admin',
  headerImg: 'https://img.alicdn.com/tfs/TB1.ZBecq67gK0jSZFHXXa9jVXa-904-826.png',
  authorityId: '',
  authority: '',
  authorities: [],
};
export default HeaderAvatar;